import { connect } from 'react-redux';
import {
    reset,
    updateData,
    postImage,
    getData,
    postEdit,
} from 'actions/floorplan';

import HomeRegister from 'views/Home/Floorplan';

const mapStateToProps = state => ({
    loading: state.floorplan.loading,
    data: state.floorplan.data,
    errors: state.floorplan.errors,
    isValid: state.floorplan.isValid,
});

const mapDispatchToProps = {
    reset,
    updateData,
    postImage,
    getData,
    postEdit,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HomeRegister);

