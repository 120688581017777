import {
    RESET_MASTER,
    UPDATE_MASTER,
    FETCH_MASTER,
    FETCH_MASTER_DATA_DONE,
    FETCH_MASTER_DONE,
    FETCH_MASTER_FAIL,
} from 'actions/masterplan';

const initialState = {
    loading: false,
    data: [],
    isValid: false,
    successMessage: '',
    failMessage: '',
};

const coverReducer = (state = initialState, action) => {
    switch (action.type) {
        case RESET_MASTER:
            return {
                ...initialState,
                data: [],
            };
        case UPDATE_MASTER:
            let newArr = [...state.data];
            newArr = [
                ...newArr.slice(0, action.order), 
                {
                    ...state.data[action.order],
                    [action.fieldName]: action.newValue,
                },
                ...newArr.slice(action.order + 1),
            ];
            return {
                ...state,
                data: newArr,
                isValid: action.isValid,
            };
        case FETCH_MASTER:
            return {
                ...state,
                loading: true,
            };
        case FETCH_MASTER_DATA_DONE:    
            return {
                ...state,
                loading: false,
                data: action.data,
            };
        case FETCH_MASTER_DONE:
            return {
                ...state,
                loading: false,
                successMessage: action.message,
            };
        case FETCH_MASTER_FAIL:
            return {
                ...state,
                loading: false,
                failMessage: action.message,
            };
        default:
            return state;
    }
};
export default coverReducer;
