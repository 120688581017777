import uuidv1 from 'uuid/v1';
import _ from 'lodash';
import imageCompression from 'browser-image-compression';
import firebase from 'common/firebase';
import { showSnack } from './main';

const options = {
    maxSizeMB: 2,
    maxWidthOrHeight: 1000,
    useWebWorker: true,
    maxIteration: 10,
};

// Action
export const RESET_CONTACT = 'RESET_CONTACT';
export const UPDATE_CONTACT = 'UPDATE_CONTACT';
export const UPDATE_CONTACT_MAP = 'UPDATE_CONTACT_MAP';
export const FETCH_CONTACT = 'FETCH_CONTACT';
export const FETCH_CONTACT_DATA_DONE = 'FETCH_CONTACT_DATA_DONE';
export const FETCH_CONTACT_DONE = 'FETCH_CONTACT_DONE';
export const FETCH_CONTACT_FAIL = 'FETCH_CONTACT_FAIL';

// Sync Action Creator
export function reset() {
    return {
        type: RESET_CONTACT,
    };
}
export function fetch() {
    return {
        type: FETCH_CONTACT,
    };
}
export function fetchDataDone(data) {
    return {
        type: FETCH_CONTACT_DATA_DONE,
        data,
    };
}
export function fetchDone(message) {
    return {
        type: FETCH_CONTACT_DONE,
        message,
    };
}
export function fetchFail(message) {
    return {
        type: FETCH_CONTACT_FAIL,
        message,
    };
}
export function updateMap(oldData, newValue) {
    oldData.image = newValue;

    return {
        type: UPDATE_CONTACT_MAP,
        newValue,
        isValid:
            !_.isEmpty(oldData.image) &&
            !_.isEmpty(oldData.projectLocation) &&
            !_.isEmpty(oldData.salesGallery),
    };
}
export function updateData(oldData, fieldName, newValue) {
    oldData[fieldName] = newValue;

    return {
        type: UPDATE_CONTACT,
        fieldName,
        newValue,
        isValid:
            !_.isEmpty(oldData.image) &&
            !_.isEmpty(oldData.projectLocation) &&
            !_.isEmpty(oldData.salesGallery),
    };
}

// Async Action Creator
export function postImage(file, oldData) {
    if (!file) {
        return {
            type: FETCH_CONTACT_FAIL,
            message: 'No selected file.',
        };
    }

    // Add file to storage
    return async dispatch => {
        dispatch(fetch());
        const compressedFile = await imageCompression(file, options);
        const storageRef = firebase.storage().ref();
        const fileRef = storageRef
            .child(`pages/${uuidv1()}-${compressedFile.name}`)
            .put(file);
        return fileRef.then(() => {
            // get download directory
            fileRef.snapshot.ref.getDownloadURL().then(downloadURL => {
                dispatch(updateMap(oldData, downloadURL));
                dispatch(fetchDone(''));
            });
        });
    };
}

// Async Action Creator
export function getData() {
    return async dispatch => {
        dispatch(fetch());

        const ref = firebase
            .firestore()
            .collection('pages')
            .doc('home');
        try {
            const result = await ref.get();
            const data = result.data().map;
            console.log(data);
            dispatch(fetchDataDone(data));
        } catch (error) {
            console.log(error);
            // doc.data() will be undefined in this case
            dispatch(showSnack('fail', 'No such document!'));
            dispatch(fetchFail('No such document!'));
        }
    };
}

export function postEdit(data) {
    return function(dispatch) {
        dispatch(fetch());

        const ref = firebase
            .firestore()
            .collection('pages')
            .doc('home');
        const inputData = {
            map: {
                image: data.image,
                projectLocation: data.projectLocation,
                salesGallery: data.salesGallery,
            },
        };

        return ref
            .set(inputData, { merge: true })
            .then(() => {
                dispatch(fetchDone('Map updated successfully.'));
                dispatch(showSnack('success', 'Map updated successfully.'));
            })
            .catch(error => {
                console.log(error);
                dispatch(fetchFail(error.message));
            });
    };
}
