import {
    RESET_INFO,
    UPDATE_INFO,
    FETCH_INFO,
    FETCH_INFO_DATA_DONE,
    FETCH_INFO_DONE,
    FETCH_INFO_FAIL,
} from 'actions/info';

const initialState = {
    loading: false,
    data: {
        file: '',
        img: '',
        name: '',
        owner: '',
        address: '',
        area:'',
        character:'',
        unit: '',
        roomType: '',
    },
    errors: {},
    isValid: false,
    successMessage: '',
    failMessage: '',
};

const conceptReducer = (state = initialState, action) => {
    switch (action.type) {
        case RESET_INFO:
            return {
                ...initialState,
                data: {
                    file: '',
                    img: '',
                    name: '',
                    owner: '',
                    address: '',
                    area: '',
                    character: '',
                    unit: '',
                    roomType: '',
                },
            };
        case UPDATE_INFO:
            return {
                ...state,
                data: {
                    ...state.data,
                    [action.fieldName]: action.newValue,
                },
                errors: action.errors,
                isValid: !action.errors,
            };
        case FETCH_INFO:
            return {
                ...state,
                loading: true,
            };
        case FETCH_INFO_DATA_DONE:    
            return {
                ...state,
                loading: false,
                data: action.data,
            };
        case FETCH_INFO_DONE:
            return {
                ...state,
                loading: false,
                successMessage: action.message,
            };
        case FETCH_INFO_FAIL:
            return {
                ...state,
                loading: false,
                failMessage: action.message,
            };
        default:
            return state;
    }
};
export default conceptReducer;
