import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
    Card,
    CardActions,
    Divider,
    Button,
    TextField,
    Box,
} from '@material-ui/core';
import ImageField from 'components/ImageField';

const useStyles = makeStyles(() => ({
    formControl: {
        width: '100%',
        maxWidth: 200,
        marginRight: 16,
    },
}));

const AccountDetails = (props) => {
    const classes = useStyles();
    const {
        loading,
        data,
        isValid,
        updateData,
        postImage,
        postEdit,
    } = props;

    // Submit Form
    const handleSave = () => {
        postEdit(data);
    };

    return (
        <Card className={classes.root}>
            <ImageField
                title={'Video 1*'}
                width={1280}
                height={720}
                loading={loading}
                url={data.cover}
                uploadFunc={postImage}
                oldData={data}
                fieldName="cover"
                removeFunc={() => updateData(data, 'cover', '')}
            />
            <Box p={2}>
                <TextField
                    fullWidth
                    label="URL 1"
                    margin="normal"
                    name="url"
                    onChange={event =>
                        updateData(
                            data,
                            'url',
                            event.target.value,
                        )
                    }
                    value={data.url}
                    variant="outlined"
                    disabled={loading}
                />
            </Box>
            <Divider />
            <ImageField
                title={'Video 2*'}
                width={1280}
                height={720}
                loading={loading}
                url={data.coverTwo}
                uploadFunc={postImage}
                oldData={data}
                fieldName="coverTwo"
                removeFunc={() => updateData(data, 'coverTwo', '')}
            />
            <Box p={2}>
                <TextField
                    fullWidth
                    label="URL 2"
                    margin="normal"
                    name="urlTwo"
                    onChange={event =>
                        updateData(
                            data,
                            'urlTwo',
                            event.target.value,
                        )
                    }
                    value={data.urlTwo}
                    variant="outlined"
                    disabled={loading}
                />
            </Box>
            <Divider />
            <CardActions style={{ padding: 16 }}>
                <Button
                    color="primary"
                    variant="contained"
                    disabled={!isValid}
                    onClick={handleSave}>
                    Save
                </Button>
            </CardActions>
        </Card>
    );
};

export default AccountDetails;
