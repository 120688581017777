import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/styles';
import { useMediaQuery, Snackbar, SnackbarContent, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';

import { Sidebar, Topbar } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: 56,
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      paddingTop: 64
    }
  },
  shiftContent: {
    paddingLeft: 240
  },
  content: {
    height: '100%'
  },
  error: {
    backgroundColor: "#de0032",
  },
  success: {
    backgroundColor: "#932d43",
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const Main = props => {
  const { 
    children, 
    user, 
    postSignOut, 
    snackIsShow,
    snackMessage,
    snackStatus,
    hideSnack 
  } = props;

  const classes = useStyles();
  const theme = useTheme();

  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true
  });

  const [openSidebar, setOpenSidebar] = useState(false);
  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };
  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };
  const shouldOpenSidebar = isDesktop ? true : openSidebar;

  function handleClose() {
    hideSnack();
  }

  let snackContent = <div></div>;
  if (snackStatus === 'success') {
    snackContent = <SnackbarContent
                    className={classes.success}
                    aria-describedby="client-snackbar"
                    message={
                      <span id="client-snackbar" className={classes.message}>
                        <CheckCircleIcon className={clsx(classes.icon, classes.iconVariant)} /> {snackMessage}
                      </span>
                    }
                    action={[
                      <IconButton key="close" aria-label="close" color="inherit" onClick={handleClose}>
                        <CloseIcon className={classes.icon} />
                      </IconButton>,
                    ]}
                  />;
  }
  if (snackStatus === 'fail') {
    snackContent = <SnackbarContent
                    className={classes.error}
                    aria-describedby="client-snackbar"
                    message={
                      <span id="client-snackbar" className={classes.message}>
                        <ErrorIcon className={clsx(classes.icon, classes.iconVariant)} /> {snackMessage}
                      </span>
                    }
                    action={[
                      <IconButton key="close" aria-label="close" color="inherit" onClick={handleClose}>
                        <CloseIcon className={classes.icon} />
                      </IconButton>,
                    ]}
                  />;
  }

  return (
    <div
      className={clsx({
        [classes.root]: true,
        [classes.shiftContent]: isDesktop
      })}
    >
      <Topbar onSidebarOpen={handleSidebarOpen} postSignOut={postSignOut} />
      <Sidebar
        onClose={handleSidebarClose}
        open={shouldOpenSidebar}
        variant={isDesktop ? 'persistent' : 'temporary'}
        postSignOut={postSignOut}
        user={user}
      />
      <main className={classes.content}>
        {children}
      </main>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        open={snackIsShow}
        autoHideDuration={5000}
        onClose={handleClose}
      >
        {snackContent}
      </Snackbar>
    </div>
  );
};

Main.propTypes = {
    children: PropTypes.node
};

export default Main;
