import { connect } from 'react-redux';
import {
    getList,
    updateTime,
} from 'actions/registers';
import Registers from 'views/Registers';

const mapStateToProps = state => ({
    loading: state.registers.loading,
    list: state.registers.list,
    start: state.registers.start,
    end: state.registers.end, 
});

const mapDispatchToProps = {
    getList,
    updateTime,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Registers);