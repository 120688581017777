import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
    Card,
    CardActions,
    Divider,
    Button,
    Typography,
    TextField,
    Box,
} from '@material-ui/core';
import ImageWithTextField from 'components/ImageWithTextField';

const useStyles = makeStyles(() => ({
    formControl: {
        width: '100%',
        maxWidth: 200,
        marginRight: 16,
    },
}));

const AccountDetails = (props) => {
    const classes = useStyles();
    const {
        loading,
        data,
        isValid,
        updateData,
        postImage,
        postEdit,
    } = props;

    // Submit Form
    const handleSave = () => {
        postEdit(data);
    };

    return (
        <Card className={classes.root}>
            {data.map((item,index) => (
                <div key={index}>
                    <Box p={2}>
                        <div style={{ marginBottom: 8 }}>
                            <Typography gutterBottom variant="h5">
                                {`Plan ${index + 1}`}
                            </Typography>
                        </div>
                        <TextField
                            fullWidth
                            required
                            label="Name"
                            margin="normal"
                            name="name"
                            onChange={event =>
                                updateData(
                                    data,
                                    index,
                                    'name',
                                    event.target.value,
                                )
                            }
                            value={item.name}
                            variant="outlined"
                            disabled={loading}
                        />
                    </Box>
                    <ImageWithTextField
                        width={1280}
                        height={720}
                        loading={loading}
                        url={item.img}
                        uploadFunc={(file) => 
                            postImage(
                                file, 
                                data, 
                                index, 
                                'img'
                            )
                        }
                        oldData={data}
                        fieldName="img"
                        order={index}
                        removeFunc={() => updateData(data, index, 'img', '')}
                    />
                    <Divider />
                </div>
            ))}
            <CardActions style={{ padding: 16 }}>
                <Button
                    color="primary"
                    variant="contained"
                    disabled={!isValid}
                    onClick={handleSave}>
                    Save
                </Button>
            </CardActions>
        </Card>
    );
};

export default AccountDetails;
