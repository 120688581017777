import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  progress: {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        margin: 'auto',
  },
}));

const RouteWithLayout = props => {
    const classes = useStyles();

    const { 
        layout: Layout, 
        component: Component, 
        noAuthRequired, 
        authRequired,
        isLogin,
        ...rest 
    } = props;

    let outputComponent = (
        <Layout>
            <Component />
        </Layout>
    );
    
    if (isLogin === -1) {
        outputComponent = (
            <CircularProgress className={classes.progress} />
        );
    }
    if (isLogin === 0 && authRequired) {
        outputComponent = (
            <Redirect to="/sign-in" />
        );
    }
    if (isLogin === 1 && noAuthRequired){
        outputComponent = (
            <Redirect to="/home/register" />
        );
    }

    React.useEffect(() => {
        const { getLogin } = props;
        getLogin();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Route
            {...rest}
            render={() => (
                outputComponent
            )}
        />
    );
};

RouteWithLayout.propTypes = {
    /* eslint-disable react/forbid-prop-types */
    component: PropTypes.any.isRequired,
    layout: PropTypes.any.isRequired,
    path: PropTypes.string.isRequired,
    noAuthRequired: PropTypes.bool,
    authRequired: PropTypes.bool,
    isLogin: PropTypes.number.isRequired,
};

RouteWithLayout.defaultProps = {
    noAuthRequired: false,
    authRequired: false,
};

export default RouteWithLayout;
