import { connect } from 'react-redux';
import {
    reset,
    updateData,
    postImage,
    getData,
    postEdit,
} from 'actions/progress';

import HomeMaterials from 'views/Home/Progress';

const mapStateToProps = state => ({
    loading: state.progress.loading,
    data: state.progress.data,
    isValid: state.progress.isValid,
});

const mapDispatchToProps = {
    reset,
    updateData,
    postImage,
    getData,
    postEdit,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HomeMaterials);

