import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
    AppBar,
    Toolbar,
    Hidden,
    IconButton,
    Divider,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import InputIcon from '@material-ui/icons/Input';
import Logo from 'common/icon/logo.png';

const useStyles = makeStyles(theme => ({
    root: {
        boxShadow: 'none',
        color: '#666666',
        backgroundColor: '#932d43',
    },
    logo: {
        height: 50,
    },
    flexGrow: {
        flexGrow: 1,
    },
    signOutButton: {
        marginLeft: theme.spacing(1),
        color: '#82bad1',
    },
}));

const Topbar = props => {
    const { className, onSidebarOpen, postSignOut, ...rest } = props;

    const classes = useStyles();

    return (
        <AppBar
            {...rest}
            className={clsx(classes.root, className)}
            color="inherit">
            <Toolbar>
                <RouterLink to="/">
                    <img className={classes.logo} alt="Logo" src={Logo} />
                </RouterLink>
                <div className={classes.flexGrow} />
                <Hidden mdDown>
                    <IconButton
                        className={classes.signOutButton}
                        color="inherit"
                        onClick={() => postSignOut()}>
                        <InputIcon />
                    </IconButton>
                </Hidden>
                <Hidden lgUp>
                    <IconButton color="inherit" onClick={onSidebarOpen}>
                        <MenuIcon />
                    </IconButton>
                </Hidden>
            </Toolbar>
            <Divider />
        </AppBar>
    );
};

Topbar.propTypes = {
    className: PropTypes.string,
    onSidebarOpen: PropTypes.func,
};

export default Topbar;
