import { combineReducers } from 'redux';
import auth from './auth';
import main from './main';
import progress from './progress';
import video from './video';
import about from './about';
import registers from './registers';
import downloads from './downloads';
import cover from './cover';
import register from './register';
import concept from './concept';
import info from './info';
import neighbour from './neighbour';
import floorplan from './floorplan';
import masterplan from './masterplan';
import unitplan from './unitplan';
import contactus from './contactus';
import interior from './interior';
import gallery from './gallery';

export default combineReducers({
    auth,
    main,
    progress,
    video,
    about,
    registers,
    downloads,
    cover,
    register,
    concept,
    info,
    neighbour,
    floorplan,
    masterplan,
    unitplan,
    contactus,
    interior,
    gallery,
});