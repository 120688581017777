import validate from 'validate.js';
import uuidv1 from 'uuid/v1';
import firebase from 'common/firebase';
import { showSnack } from './main';

const schema = {
    img: {
        presence: { allowEmpty: false, message: 'is required' },
    },
    desc: {
        presence: { allowEmpty: false, message: 'is required' },
    },
    allPercent: {
        presence: { allowEmpty: false, message: 'is required' },
    },
    archPercent: {
        presence: { allowEmpty: false, message: 'is required' },
    },
    strPercent: {
        presence: { allowEmpty: false, message: 'is required' },
    },
    sysPercent: {
        presence: { allowEmpty: false, message: 'is required' },
    },
};

// Action
export const RESET_PROGRESS = 'RESET_PROGRESS';
export const UPDATE_PROGRESS = 'UPDATE_PROGRESS';
export const FETCH_PROGRESS = 'FETCH_PROGRESS';
export const FETCH_PROGRESS_DATA_DONE = 'FETCH_PROGRESS_DATA_DONE';
export const FETCH_PROGRESS_DONE = 'FETCH_PROGRESS_DONE';
export const FETCH_PROGRESS_FAIL = 'FETCH_PROGRESS_FAIL';

// Sync Action Creator
export function reset() {
    return {
        type: RESET_PROGRESS,
    };
};
export function fetch() {
    return {
        type: FETCH_PROGRESS,
    };
};
export function fetchDataDone(data) {
    return {
        type: FETCH_PROGRESS_DATA_DONE,
        data,
    };
};
export function fetchDone(message) {
    return {
        type: FETCH_PROGRESS_DONE,
        message,
    };
};
export function fetchFail(message) {
    return {
        type: FETCH_PROGRESS_FAIL,
        message,
    };
};
export function updateData(oldData, fieldName, newValue) {
    oldData[fieldName] = newValue;
    const validateData = oldData;
    const errors = validate(validateData, schema);

    return {
        type: UPDATE_PROGRESS,
        fieldName,
        newValue,
        errors: errors,
    };
};

// Async Action Creator
export function postImage(file, oldData, fieldName){
    if (!file) {
        return {
            type: FETCH_PROGRESS_FAIL,
            message: 'No selected file.',
        };
    }

    // Add file to storage
    return function (dispatch) {
        dispatch(fetch());        
        const storageRef = firebase.storage().ref();
        const fileRef = storageRef.child(`pages/${uuidv1()}-${file.name}`).put(file);
        return fileRef.then(() => {
            // get download directory
            fileRef.snapshot.ref.getDownloadURL().then((downloadURL) => {
                dispatch(updateData(oldData, fieldName, downloadURL));
                dispatch(fetchDone(''));
            });
        });
    };
}


// Async Action Creator
export function getData() {
    return async (dispatch) => {
        dispatch(fetch());

        const ref = firebase.firestore().collection("pages").doc("home");
        try {
            const result = await ref.get();
            const data = result.data().progress;
            const output = {
                img: data.img,
                desc: data.desc,
                allPercent: data.allPercent,
                archPercent: data.archPercent,
                strPercent: data.strPercent,
                sysPercent: data.sysPercent,
            };
            dispatch(fetchDataDone(output));
        }
        catch (error) {
            console.log(error);
            // doc.data() will be undefined in this case
            dispatch(showSnack('fail', "No such document!"));
            dispatch(fetchFail("No such document!"));
        }
    };
}

export function postEdit(data) {
    return function (dispatch) {
        dispatch(fetch());
        
        const ref = firebase.firestore().collection("pages").doc("home");

        const inputData = {
            progress: {
                img: data.img,
                desc: data.desc,
                allPercent: data.allPercent,
                archPercent: data.archPercent,
                strPercent: data.strPercent,
                sysPercent: data.sysPercent,
            },
        };

        return ref.set(inputData, { merge: true }).then(() => {
            dispatch(fetchDone('Progress updated successfully.'));
            dispatch(showSnack('success', 'Progress updated successfully.'));
        }).catch((error) => {
            console.log(error);
            dispatch(fetchFail(error.message));
        });
    };
}