import firebase from 'common/firebase';

// Action
export const FETCH_DOWNLOADS = 'FETCH_DOWNLOADS';
export const UPDATE_DOWNLOADS = 'UPDATE_DOWNLOADS';
export const FETCH_DOWNLOADS_DONE = 'FETCH_DOWNLOADS_DONE';
export const FETCH_DOWNLOADS_FAIL = 'FETCH_DOWNLOADS_FAIL';
export const FETCH_DOWNLOADS_LIST_DONE = 'FETCH_DOWNLOADS_LIST_DONE';

// Sync Action Creator
export function fetch() {
    return {
        type: FETCH_DOWNLOADS,
    };
};
export function fetchDone(message) {
    return {
        type: FETCH_DOWNLOADS_DONE,
        message,
    };
};
export function fetchFail(message) {
    return {
        type: FETCH_DOWNLOADS_FAIL,
        message,
    };
};
export function updateTime(field, value){
    return {
        type: UPDATE_DOWNLOADS,
        field,
        value,
    };
}

// -- LIST --
export function fetchListDone(data) {
    return {
        type: FETCH_DOWNLOADS_LIST_DONE,
        list: data,
    };
};

// Async Action Creator
export function getList(start,end){
    return async function (dispatch) {
        dispatch(fetch());

        const ref = firebase.firestore().collection("downloads").where("timestamp", ">", start).orderBy("timestamp", "desc");
        try {
            const snapshot = await ref.get();
            const list = [];
            snapshot.forEach((doc) => {
                const docData = doc.data();
                if (docData.timestamp < end){
                    const data = {
                        ...docData,
                        uid: doc.id,
                    }
                    list.push(data);
                }
            });
            dispatch(fetchListDone(list));
        }
        catch (error) {
            console.log(error);
            dispatch(fetchFail(error.message));
        }
    };
}