import { connect } from 'react-redux';
import {
    reset,
    updateData,
    postImage,
    getData,
    postEdit,
} from 'actions/masterplan';

import HomeRegister from 'views/Home/Masterplan';

const mapStateToProps = state => ({
    loading: state.masterplan.loading,
    data: state.masterplan.data,
    errors: state.masterplan.errors,
    isValid: state.masterplan.isValid,
});

const mapDispatchToProps = {
    reset,
    updateData,
    postImage,
    getData,
    postEdit,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HomeRegister);

