import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import RouteWithLayout from 'containers/RouteWithLayout';
import MainLayout from 'containers/MainLayout';
import SignInView from 'containers/SignIn';
import RegistersView from 'containers/Registers';
import HomeInfoView from 'containers/HomeInfo';
import HomeProgressView from 'containers/HomeProgress';
import HomeVideoView from 'containers/HomeVideo';
import HomeMasterplanView from 'containers/HomeMasterplan';
import HomeUnitplanView from 'containers/HomeUnitplan';
import HomeFloorplanView from 'containers/HomeFloorplan';
import HomeRegisterView from 'containers/HomeRegister';
import HomeGalleryView from 'containers/HomeNeighbour';
import HomeContactView from 'containers/HomeContact';
import { Minimal as MinimalLayout } from './layouts';
import { NotFound as NotFoundView } from './views';

const Routes = () => {
    return (
        <Switch>
            <Redirect exact from="/" to="/sign-in" />
            {/* Authentication */}
            <RouteWithLayout
                layout={MinimalLayout}
                component={SignInView}
                exact
                path="/sign-in"
                noAuthRequired
            />
            {/* Registers */}
            <RouteWithLayout
                layout={MainLayout}
                component={RegistersView}
                exact
                path="/registers"
                authRequired
            />
            {/* Home */}
            <RouteWithLayout
                layout={MainLayout}
                component={HomeRegisterView}
                exact
                path="/home/register"
                authRequired
            />
            <RouteWithLayout
                layout={MainLayout}
                component={HomeInfoView}
                exact
                path="/home/info"
                authRequired
            />
            <RouteWithLayout
                layout={MainLayout}
                component={HomeMasterplanView}
                exact
                path="/home/masterplan"
                authRequired
            />
            <RouteWithLayout
                layout={MainLayout}
                component={HomeUnitplanView}
                exact
                path="/home/unitplan"
                authRequired
            />
            <RouteWithLayout
                layout={MainLayout}
                component={HomeFloorplanView}
                exact
                path="/home/floorplan"
                authRequired
            />
            <RouteWithLayout
                layout={MainLayout}
                component={HomeGalleryView}
                exact
                path="/home/gallery"
                authRequired
            />
            <RouteWithLayout
                layout={MainLayout}
                component={HomeVideoView}
                exact
                path="/home/video"
                authRequired
            />
            <RouteWithLayout
                layout={MainLayout}
                component={HomeContactView}
                exact
                path="/home/map"
                authRequired
            />
            <RouteWithLayout
                layout={MainLayout}
                component={HomeProgressView}
                exact
                path="/home/progress"
                authRequired
            />

            {/* Not Found */}
            <Redirect exact from="/home/" to="/home/register" />
            <RouteWithLayout
                layout={MinimalLayout}
                component={NotFoundView}
                exact
                path="/not-found"
            />
            <Redirect to="/not-found" />
        </Switch>
    );
};

export default Routes;
