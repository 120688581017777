import {
    RESET_PROGRESS,
    UPDATE_PROGRESS,
    FETCH_PROGRESS,
    FETCH_PROGRESS_DATA_DONE,
    FETCH_PROGRESS_DONE,
    FETCH_PROGRESS_FAIL,
} from 'actions/progress';

const initialState = {
    loading: false,
    data: {
        img: '',
        desc: '',
        allPercent: '',
        archPercent: '',
        strPercent: '',
        sysPercent: '',
    },
    errors: {},
    isValid: false,
    successMessage: '',
    failMessage: '',
};

const conceptReducer = (state = initialState, action) => {
    switch (action.type) {
        case RESET_PROGRESS:
            return {
                ...initialState,
                data: {
                    img: '',
                    desc: '',
                    allPercent: '',
                    archPercent: '',
                    strPercent: '',
                    sysPercent: '',
                },
            };
        case UPDATE_PROGRESS:            
            return {
                ...state,
                data: {
                    ...state.data,
                    [action.fieldName]: action.newValue,
                },
                errors: action.errors,
                isValid: !action.errors,
            };
        case FETCH_PROGRESS:
            return {
                ...state,
                loading: true,
            };
        case FETCH_PROGRESS_DATA_DONE:    
            return {
                ...state,
                loading: false,
                data: action.data,
            };
        case FETCH_PROGRESS_DONE:
            return {
                ...state,
                loading: false,
                successMessage: action.message,
            };
        case FETCH_PROGRESS_FAIL:
            return {
                ...state,
                loading: false,
                failMessage: action.message,
            };
        default:
            return state;
    }
};
export default conceptReducer;
