import {
    RESET_FLOOR,
    UPDATE_FLOOR,
    FETCH_FLOOR,
    FETCH_FLOOR_DATA_DONE,
    FETCH_FLOOR_DONE,
    FETCH_FLOOR_FAIL,
} from 'actions/floorplan';

const initialState = {
    loading: false,
    data: [],
    isValid: false,
    successMessage: '',
    failMessage: '',
};

const coverReducer = (state = initialState, action) => {
    switch (action.type) {
        case RESET_FLOOR:
            return {
                ...initialState,
                data: [],
            };
        case UPDATE_FLOOR:
            let newArr = [...state.data];
            newArr = [
                ...newArr.slice(0, action.order),
                {
                    ...state.data[action.order],
                    [action.fieldName]: action.newValue,
                },
                ...newArr.slice(action.order + 1),
            ];
            return {
                ...state,
                data: newArr,
                isValid: action.isValid,
            };
        case FETCH_FLOOR:
            return {
                ...state,
                loading: true,
            };
        case FETCH_FLOOR_DATA_DONE:
            return {
                ...state,
                loading: false,
                data: action.data,
            };
        case FETCH_FLOOR_DONE:
            return {
                ...state,
                loading: false,
                successMessage: action.message,
            };
        case FETCH_FLOOR_FAIL:
            return {
                ...state,
                loading: false,
                failMessage: action.message,
            };
        default:
            return state;
    }
};
export default coverReducer;
