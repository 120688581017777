import {
    SET_LANG,
    RESET_ABOUT,
    UPDATE_ABOUT,
    FETCH_ABOUT,
    FETCH_ABOUT_DATA_DONE,
    FETCH_ABOUT_DONE,
    FETCH_ABOUT_FAIL,
} from 'actions/about';

const initialState = {
    loading: false,
    lang: 'th',
    data: {
        cover: '',
        desc: {
            th: '',
            en: '',
        },
    },
    errors: {},
    isValid: false,
    successMessage: '',
    failMessage: '',
};

const progressReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_LANG:
            return {
                ...state,
                lang: action.lang,
            };
        case RESET_ABOUT:
            return {
                ...initialState,
                data: {
                    cover: '',
                    desc: {
                        th: '',
                        en: '',
                    },
                },
            };
        case UPDATE_ABOUT:
            if (action.fieldName === 'cover') {
                return {
                    ...state,
                    data: {
                        ...state.data,
                        cover: action.newValue
                    },
                    errors: action.errors,
                    isValid: !action.errors,
                };
            }

            return {
                ...state,
                data: {
                    ...state.data,
                    desc: {
                        ...state.data.desc,
                        [action.lang]: action.newValue,
                    },
                },
                errors: action.errors,
                isValid: !action.errors,
            };
        case FETCH_ABOUT:
            return {
                ...state,
                loading: true,
            };
        case FETCH_ABOUT_DATA_DONE:
            return {
                ...state,
                loading: false,
                data: action.data,
            };
        case FETCH_ABOUT_DONE:
            return {
                ...state,
                loading: false,
                successMessage: action.message,
            };
        case FETCH_ABOUT_FAIL:
            return {
                ...state,
                loading: false,
                failMessage: action.message,
            };
        default:
            return state;
    }
};
export default progressReducer;
