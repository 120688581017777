import uuidv1 from 'uuid/v1';
import _ from 'lodash';
import firebase from 'common/firebase';
import { showSnack } from './main';

// Action
export const RESET_MASTER = 'RESET_MASTER';
export const UPDATE_MASTER = 'UPDATE_MASTER';
export const FETCH_MASTER = 'FETCH_MASTER';
export const FETCH_MASTER_DATA_DONE = 'FETCH_MASTER_DATA_DONE';
export const FETCH_MASTER_DONE = 'FETCH_MASTER_DONE';
export const FETCH_MASTER_FAIL = 'FETCH_MASTER_FAIL';

// Sync Action Creator
export function reset() {
    return {
        type: RESET_MASTER,
    };
};
export function fetch() {
    return {
        type: FETCH_MASTER,
    };
};
export function fetchDataDone(data) {
    return {
        type: FETCH_MASTER_DATA_DONE,
        data,
    };
};
export function fetchDone(message) {
    return {
        type: FETCH_MASTER_DONE,
        message,
    };
};
export function fetchFail(message) {
    return {
        type: FETCH_MASTER_FAIL,
        message,
    };
};
export function updateData(oldData, order, fieldName, newValue) {
    oldData[order][fieldName] = newValue;
    
    const emptyName = _.filter(oldData, ['name', '']);
    const emptyImg = _.filter(oldData, ['img', '']);

    return {
        type: UPDATE_MASTER,
        order,
        fieldName,
        newValue,
        isValid: emptyName.length === 0 && emptyImg.length === 0,
    };
};

// Async Action Creator
export function postImage(file, oldData, order, fieldName){
    if (!file) {
        return {
            type: FETCH_MASTER_FAIL,
            message: 'No selected file.',
        };
    }

    // Add file to storage
    return function (dispatch) {
        dispatch(fetch());        
        const storageRef = firebase.storage().ref();
        const fileRef = storageRef.child(`pages/${uuidv1()}-${file.name}`).put(file);
        return fileRef.then(() => {
            // get download directory
            fileRef.snapshot.ref.getDownloadURL().then((downloadURL) => {
                dispatch(updateData(oldData, order, fieldName, downloadURL));
                dispatch(fetchDone(''));
            });
        });
    };
}


// Async Action Creator
export function getData() {
    return async (dispatch) => {
        dispatch(fetch());

        const ref = firebase.firestore().collection("pages").doc("home");
        try {
            const result = await ref.get();
            let data = result.data().masterplan;
            console.log(data);
            dispatch(fetchDataDone(data));
        }
        catch (error) {
            console.log(error);
            // doc.data() will be undefined in this case
            dispatch(showSnack('fail', "No such document!"));
            dispatch(fetchFail("No such document!"));
        }
    };
}

export function postEdit(data) {
    return function (dispatch) {
        dispatch(fetch());
        
        const ref = firebase.firestore().collection("pages").doc("home");
        const inputData = {
            masterplan: data,
        };

        return ref.set(inputData, { merge: true }).then(() => {
            dispatch(fetchDone('Floor plan updated successfully.'));
            dispatch(showSnack('success', 'Floor plan updated successfully.'));
        }).catch((error) => {
            console.log(error);
            dispatch(fetchFail(error.message));
        });
    };
}