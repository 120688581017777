import validate from 'validate.js';
import uuidv1 from 'uuid/v1';
import firebase from 'common/firebase';
import { showSnack } from './main';

const schema = {
    cover: {
        presence: { allowEmpty: false, message: 'is required' },
    },
    descTh: {
        presence: { allowEmpty: false, message: 'is required' },
    },
    descEn: {
        presence: { allowEmpty: false, message: 'is required' },
    },
};

// Action
export const SET_LANG = 'SET_LANG';
export const RESET_ABOUT = 'RESET_ABOUT';
export const UPDATE_ABOUT = 'UPDATE_ABOUT';
export const FETCH_ABOUT = 'FETCH_ABOUT';
export const FETCH_ABOUT_DATA_DONE = 'FETCH_ABOUT_DATA_DONE';
export const FETCH_ABOUT_DONE = 'FETCH_ABOUT_DONE';
export const FETCH_ABOUT_FAIL = 'FETCH_ABOUT_FAIL';

// Sync Action Creator
export function changeLang(lang) {
    return {
        type: SET_LANG,
        lang
    };
};
export function reset() {
    return {
        type: RESET_ABOUT,
    };
};
export function fetch() {
    return {
        type: FETCH_ABOUT,
    };
};
export function fetchDataDone(data) {
    return {
        type: FETCH_ABOUT_DATA_DONE,
        data,
    };
};
export function fetchDone(message) {
    return {
        type: FETCH_ABOUT_DONE,
        message,
    };
};
export function fetchFail(message) {
    return {
        type: FETCH_ABOUT_FAIL,
        message,
    };
};
export function updateData(oldData, lang, fieldName, newValue) {
    if (lang === ''){
        oldData[fieldName] = newValue;
    } else {
        oldData[fieldName][lang] = newValue;
    }
    
    const validateData = {
        cover: oldData.cover,
        descTh: oldData.desc.th,
        descEn: oldData.desc.en,
    }
    const errors = validate(validateData, schema);

    return {
        type: UPDATE_ABOUT,
        lang,
        fieldName,
        newValue,
        errors: errors,
    };
};

// Async Action Creator
export function postImage(file, oldData, fieldName){
    if (!file) {
        return {
            type: FETCH_ABOUT_FAIL,
            message: 'No selected file.',
        };
    }

    // Add file to storage
    return function (dispatch) {
        dispatch(fetch());        
        const storageRef = firebase.storage().ref();
        const fileRef = storageRef.child(`pages/${uuidv1()}-${file.name}`).put(file);
        return fileRef.then(() => {
            // get download directory
            fileRef.snapshot.ref.getDownloadURL().then((downloadURL) => {
                dispatch(updateData(oldData,'', fieldName, downloadURL));
                dispatch(fetchDone(''));
            });
        });
    };
}


// Async Action Creator
export function getData() {
    return async (dispatch) => {
        dispatch(fetch());

        const ref = firebase.firestore().collection("pages").doc("aboutus");
        try {
            const result = await ref.get();
            const data = result.data();
            const output = {
                cover: data.img,
                desc: data.desc,
            };
            dispatch(fetchDataDone(output));
        }
        catch (error) {
            console.log(error);
            // doc.data() will be undefined in this case
            dispatch(showSnack('fail', "No such document!"));
            dispatch(fetchFail("No such document!"));
        }
    };
}

export function postEdit(data) {
    return function (dispatch) {
        dispatch(fetch());
        
        const ref = firebase.firestore().collection("pages").doc("aboutus");

        const inputData = {
            img: data.cover,
            desc: data.desc,
        };

        return ref.set(inputData, { merge: true }).then(() => {
            dispatch(fetchDone('About us updated successfully.'));
            dispatch(showSnack('success', 'About us updated successfully.'));
        }).catch((error) => {
            console.log(error);
            dispatch(fetchFail(error.message));
        });
    };
}