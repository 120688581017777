import {
    SIGN_IN,
    NOT_SIGN_IN,
    UPDATE_SIGN_IN,
    SIGN_IN_SUCCESS,
    SIGN_IN_FAIL,
    CHECK_LOGIN,
    SIGN_OUT,
    IS_LOADING,
    LOADING_DONE,
    RESET_AUTH,
} from '../actions/auth';

const initialState = {
    isValid: false,
    loading: false,
    isLogin: -1,
    submitDone: false,
    data: {
        email: '',
        password: '',
    },
    errors: {},
    touched: {},
    message: '',
    user: {
        name: '',
        profile: '',
    },
    successMessage: '',
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
    case RESET_AUTH:
        return {
            ...state,
            data: {
                email: '',
                password: '',
            },
            errors: {},
            touched: {},
            message: '',
            user: {
                name: '',
                profile: '',
            },
            successMessage: '',
        };
    case CHECK_LOGIN:
        return {
            ...state,
            loading: true,
        };
    case NOT_SIGN_IN:
        return {
            ...state,
            loading: false,
            isLogin: 0,
        };
    case UPDATE_SIGN_IN:
        return {
            ...state,
            data: {
                ...state.data,
                [action.fieldName]: action.newValue
            },
            touched: {
                ...state.touched,
                [action.fieldName]: true,
            },
            errors: action.errors,
            isValid: !action.errors,
        };
    case SIGN_IN:
        return {
            ...state,
            loading: true,
        };
    case SIGN_IN_SUCCESS:
        return {
            ...state,
            loading: false,
            isLogin: 1,
            user: action.user,
        };
    case SIGN_IN_FAIL:
        return {
            ...state,
            message: action.message,
            successMessage: '',
            loading: false,
            isLogin: 0,
        };
    case SIGN_OUT:
        return initialState;
    case IS_LOADING:
        return {
            ...state,
            loading: true,
        };
    case LOADING_DONE:
        return {
            ...state,
            loading: false,
            successMessage: action.successMessage,
            message: '',
        };
    default:
        return state;
    }
};
export default authReducer;