import { colors } from '@material-ui/core';

const white = '#FFFFFF';
const black = '#000000';

export default {
    black,
    white,
    common: {
        black: "#5c1b29",
        white: "#fff"
    },
    background: {
        default: "#f4f4f4",
        paper: "#fff"
    },
    primary: {
        light: "rgba(120, 119, 115, 1)",
        main: "#932d43",
        dark: "#5c1b29",
        contrastText: "#fff"
    },
    secondary: {
        light: "rgba(217, 210, 200, 1)",
        main: "#932d43",
        dark: "#5c1b29",
        contrastText: "#932d43"
    },
    error: {
        main: "#ff334c",
        contrastText: "#fff"
    },
    text: {
        primary: "#33312e",
        secondary: "#932d43",
        disabled: "#dddedf",
        hint: "rgba(217, 210, 200, 1)"
    },
    success: {
        contrastText: white,
        light: "#f8b9a9",
        main: "#f06642",
        dark: "#c55437",
    },
    info: {
        contrastText: white,
        dark:  "#2e2e2e",
        main: "#666666",
        light: "#c7c7c7"
    },
    warning: {
        contrastText: white,
        dark: colors.orange[900],
        main: colors.orange[600],
        light: colors.orange[400]
    },
    icon: "#666666",
    divider: colors.grey[200]
};
