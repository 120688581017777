import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Toolbar, Table } from './components';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3)
    },
    content: {
        marginTop: theme.spacing(2),
        paddingTop: theme.spacing(1),
    }
}));

const List = (props) => {
    const classes = useStyles();
    const {
        loading,
        start,
        end,
        list,
        getList,
        updateTime,
    } = props;

    React.useEffect(() => {
        getList(start,end);
    }, [getList,start,end]);

    return (
        <div className={classes.root}>
            <Toolbar 
                start={start}
                end={end}
                update={updateTime}
                data={list}
            />
            <div className={classes.content}>
                <Table 
                    list={list} 
                    loading={loading}
                />
            </div>
        </div>
    );
};

export default List;
