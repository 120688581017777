import validate from 'validate.js';
import firebase from 'common/firebase';

// Action
export const CHECK_LOGIN = 'CHECK_LOGIN';
export const NOT_SIGN_IN = 'NOT_SIGN_IN';
export const UPDATE_SIGN_IN = 'UPDATE_SIGN_IN';
export const SIGN_IN = 'SIGN_IN';
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const SIGN_IN_FAIL = 'SIGN_IN_FAIL';
export const SIGN_OUT = 'SIGN_OUT';
export const IS_LOADING = 'IS_LOADING';
export const LOADING_DONE = 'LOADING_DONE';
export const RESET_AUTH = 'RESET_AUTH';

const schema = {
    email: {
        presence: { allowEmpty: false, message: 'is required' },
        email: true,
        length: {
            maximum: 64
        }
    },
    password: {
        presence: { allowEmpty: false, message: 'is required' },
        length: {
            maximum: 128
        }
    }
};

// Sync Action Creator
export function resetAuth() {
    return {
        type: RESET_AUTH,
    };
};
export function checkLogin() {
    return {
        type: CHECK_LOGIN,
    };
};
export function notSignIn() {
    return {
        type: NOT_SIGN_IN,
    };
};
export function updateSignIn(oldData, fieldName, newValue) {
    oldData[fieldName] = newValue;
    const errors = validate(oldData, schema);

    return {
        type: UPDATE_SIGN_IN,
        fieldName,
        newValue,
        errors: errors,
    };
}

export function signIn() {
    return {
        type: SIGN_IN,
    };
}

export function signInSuccess(user) {
    return {
        type: SIGN_IN_SUCCESS,
        user
    };
};

export function signInFail(message) {
    return {
        type: SIGN_IN_FAIL,
        message,
    };
};

export function signOut() {
    return {
        type: SIGN_OUT,
    };
};
export function isLoading() {
    return {
        type: IS_LOADING,
    };
};
export function loadingDone(successMessage) {
    return {
        type: LOADING_DONE,
        successMessage,
    };
};

// Async Action Creator
export function getLogin() {
    return function (dispatch) {
        dispatch(checkLogin());
        return firebase.auth().onAuthStateChanged(function (user) {
            if (user) {
                // User is signed in.
                const data = {};
                data.uid = user.uid;
                data.name = user.displayName;
                data.profile = user.photoURL;
                dispatch(signInSuccess(data));
            } else {
                // No user is signed in.
                dispatch(notSignIn());
            }
        });
    };
}

export function postSignIn(data) {
    return function (dispatch) {
        dispatch(signIn());
        return firebase.auth().signInWithEmailAndPassword(data.email, data.password).then(function () {
                dispatch(getLogin());
            }).catch(function (error) {
            dispatch(signInFail(error.message));
        });
    };
}

export function postSignOut(message) {
    if(message){
        // when user is not admin
        return function (dispatch) {
            dispatch(isLoading());
            return firebase.auth().signOut().then(function () {
                dispatch(signInFail(message));
            }).catch(function (error) {
                console.log(error);
            });
        };
    }else{
        return function (dispatch) {
            dispatch(isLoading());
            return firebase.auth().signOut().then(function () {
                dispatch(signOut());
            }).catch(function (error) {
                console.log(error);
            });
        };
    }
}