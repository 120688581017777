import React, { forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Divider, Drawer, Hidden, List, ListItem, Button, colors } from '@material-ui/core';
// import SaveAltIcon from '@material-ui/icons/SaveAlt';
import PeopleOutlinedIcon from '@material-ui/icons/PeopleOutlined';
// import SortIcon from '@material-ui/icons/Sort';
// import AccessibilityIcon from '@material-ui/icons/Accessibility';
import WebOutlinedIcon from '@material-ui/icons/WebOutlined';

import { SidebarNav } from './components';

const useStyles = makeStyles(theme => ({
    drawer: {
        width: 240,
        [theme.breakpoints.up('lg')]: {
            marginTop: 64,
            height: 'calc(100% - 64px)'
        }
    },
    root: {
        backgroundColor: theme.palette.white,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        padding: '0px 16px',
    },
    divider: {
        margin: theme.spacing(2, 0)
    },
    nav: {
        marginBottom: theme.spacing(2)
    },
    item: {
        display: 'flex',
        paddingTop: 0,
        paddingBottom: 0
    },
    button: {
        color: colors.blueGrey[800],
        padding: '10px 8px',
        justifyContent: 'flex-start',
        textTransform: 'none',
        letterSpacing: 0,
        width: '100%',
        fontWeight: theme.typography.fontWeightMedium
    },
    icon: {
        color: theme.palette.icon,
        width: 24,
        height: 24,
        display: 'flex',
        alignItems: 'center',
        marginRight: theme.spacing(1)
    },
    active: {
        color: theme.palette.primary.main,
        fontWeight: '600 !important',
        '& $icon': {
            color: theme.palette.primary.main
        }
    }
}));

const CustomRouterLink = forwardRef((props, ref) => (
    <div
        ref={ref}
        style={{ flexGrow: 1 }}
    >
        <RouterLink {...props} />
    </div>
));

const Sidebar = props => {
    const { open, variant, onClose, className, postSignOut,user, ...rest } = props;

    const classes = useStyles();
    const pages = [
        {
            title: 'Home',
            href: '/home/',
            icon: <WebOutlinedIcon />
        },
        // {
        //     title: 'About Us',
        //     href: '/about',
        //     icon: <AccessibilityIcon />
        // },
        // {
        //     title: 'Project Progress',
        //     href: '/progress',
        //     icon: <SortIcon />
        // },
    ];
    const LowerPages = [
        {
            title: 'Registers',
            href: '/registers',
            icon: <PeopleOutlinedIcon />
        },
        // {
        //     title: 'Downloads',
        //     href: '/downloads',
        //     icon: <SaveAltIcon />
        // }
    ];

    return (
        <Drawer
            anchor="left"
            classes={{ paper: classes.drawer }}
            onClose={onClose}
            open={open}
            variant={variant}
        >
            <div
                {...rest}
                className={clsx(classes.root, className)}
            >
                <SidebarNav
                    pages={pages}
                />
                <Divider />
                <Hidden mdDown>
                    <SidebarNav
                        pages={LowerPages}
                    />
                </Hidden>
                <Hidden lgUp>
                    <List>
                        {LowerPages.map(page => (
                            <ListItem
                                className={classes.item}
                                disableGutters
                                key={page.title}
                            >
                                <Button
                                    activeClassName={classes.active}
                                    className={classes.button}
                                    component={CustomRouterLink}
                                    to={page.href}
                                >
                                    <div className={classes.icon}>{page.icon}</div>
                                    {page.title}
                                </Button>
                            </ListItem>
                        ))}
                    </List>
                </Hidden>
            </div>
        </Drawer>
    );
};

Sidebar.propTypes = {
    className: PropTypes.string,
    onClose: PropTypes.func,
    open: PropTypes.bool.isRequired,
    variant: PropTypes.string.isRequired
};

export default Sidebar;
