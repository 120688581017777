import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
    Card,
    CardActions,
    Divider,
    Button,
    TextField,
    Box,
} from '@material-ui/core';
import ImageField from 'components/ImageField';
import FileField from 'components/FileField';

const useStyles = makeStyles(() => ({
    formControl: {
        width: '100%',
        maxWidth: 200,
        marginRight: 16,
    },
}));

const AccountDetails = (props) => {
    const classes = useStyles();
    const {
        loading,
        data,
        isValid,
        updateData,
        postImage,
        postEdit,
    } = props;

    // Typing
    const handleChange = event => {
        updateData(data, event.target.name, event.target.value);
    };

    // Submit Form
    const handleSave = () => {
        postEdit(data);
    };

    return (
        <Card className={classes.root}>
            <ImageField
                title={'Image*'}
                width={1080}
                height={1920}
                loading={loading}
                url={data.img}
                uploadFunc={postImage}
                oldData={data}
                fieldName="img"
                removeFunc={() => updateData(data, 'img', '')}
            />
            <Divider />
            <Box p={2}>
                <TextField
                    fullWidth
                    label="ชื่อโครงการ"
                    margin="normal"
                    name="name"
                    onChange={handleChange}
                    required
                    value={data.name}
                    variant="outlined"
                    disabled={loading}
                />
                <TextField
                    fullWidth
                    label="เจ้าของโครงการ"
                    margin="normal"
                    name="owner"
                    onChange={handleChange}
                    required
                    value={data.owner}
                    variant="outlined"
                    disabled={loading}
                />
                <TextField
                    fullWidth
                    label="ที่ตั้งโครงการ"
                    margin="normal"
                    name="address"
                    onChange={handleChange}
                    required
                    value={data.address}
                    variant="outlined"
                    disabled={loading}
                />
                <TextField
                    fullWidth
                    label="พื้นที่โครงการ"
                    margin="normal"
                    name="area"
                    onChange={handleChange}
                    required
                    value={data.area}
                    variant="outlined"
                    disabled={loading}
                />
                <TextField
                    fullWidth
                    label="ลักษณะโครงการ"
                    margin="normal"
                    name="character"
                    onChange={handleChange}
                    required
                    value={data.character}
                    variant="outlined"
                    disabled={loading}
                />
                <TextField
                    fullWidth
                    label="จำนวนยูนิต"
                    margin="normal"
                    name="unit"
                    onChange={handleChange}
                    required
                    value={data.unit}
                    variant="outlined"
                    disabled={loading}
                />
                <TextField
                    fullWidth
                    multiline
                    rows={4}
                    label="ประเภทห้อง"
                    margin="normal"
                    name="roomType"
                    onChange={handleChange}
                    required
                    value={data.roomType}
                    variant="outlined"
                    disabled={loading}
                />
            </Box>
            <Divider />
            <FileField
                title={'E-Brochure File*'}
                loading={loading}
                url={data.file}
                uploadFunc={postImage}
                oldData={data}
                fieldName="file"
                removeFunc={() => updateData(data, 'file', '')}
            />
            <Divider />
            <CardActions style={{ padding: 16 }}>
                <Button
                    color="primary"
                    variant="contained"
                    disabled={!isValid}
                    onClick={handleSave}>
                    Save
                </Button>
            </CardActions>
        </Card>
    );
};

export default AccountDetails;
