import uuidv1 from 'uuid/v1';
import _ from 'lodash';
import imageCompression from 'browser-image-compression';
import firebase from 'common/firebase';
import { showSnack } from './main';

const options = {
    maxSizeMB: 2,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
    maxIteration: 10,
};

// Action
export const RESET_NEIGHBOUR = 'RESET_NEIGHBOUR';
export const UPDATE_NEIGHBOUR = 'UPDATE_NEIGHBOUR';
export const ADD_NEIGHBOUR = 'ADD_NEIGHBOUR';
export const REMOVE_NEIGHBOUR = 'REMOVE_NEIGHBOUR';
export const FETCH_NEIGHBOUR = 'FETCH_NEIGHBOUR';
export const FETCH_NEIGHBOUR_DATA_DONE = 'FETCH_NEIGHBOUR_DATA_DONE';
export const FETCH_NEIGHBOUR_DONE = 'FETCH_NEIGHBOUR_DONE';
export const FETCH_NEIGHBOUR_FAIL = 'FETCH_NEIGHBOUR_FAIL';

// Sync Action Creator
export function reset() {
    return {
        type: RESET_NEIGHBOUR,
    };
}
export function fetch() {
    return {
        type: FETCH_NEIGHBOUR,
    };
}
export function fetchDataDone(data) {
    return {
        type: FETCH_NEIGHBOUR_DATA_DONE,
        data,
    };
}
export function fetchDone(message) {
    return {
        type: FETCH_NEIGHBOUR_DONE,
        message,
    };
}
export function fetchFail(message) {
    return {
        type: FETCH_NEIGHBOUR_FAIL,
        message,
    };
}
export function addList() {
    return {
        type: ADD_NEIGHBOUR,
    };
}
export function removeList(oldData, index) {
    let newArr = [...oldData];
    const newList = [...newArr.slice(0, index), ...newArr.slice(index + 1)];
    oldData = newList;
    const emptyImg = _.filter(oldData, ['img', '']);
    return {
        type: REMOVE_NEIGHBOUR,
        data: oldData,
        isValid: emptyImg.length === 0,
    };
}
export function updateData(oldData, order, newValue) {
    oldData[order] = newValue;
    const emptyImg = _.filter(oldData, ['img', '']);

    return {
        type: UPDATE_NEIGHBOUR,
        order,
        newValue,
        isValid: emptyImg.length === 0,
    };
}

// Async Action Creator
export function postImage(file, oldData, order) {
    if (!file) {
        return {
            type: FETCH_NEIGHBOUR_FAIL,
            message: 'No selected file.',
        };
    }

    // Add file to storage
    return async (dispatch) => {
        dispatch(fetch());
        const compressedFile = await imageCompression(file, options);
        const storageRef = firebase.storage().ref();
        const fileRef = storageRef
            .child(`pages/${uuidv1()}-${compressedFile.name}`)
            .put(compressedFile);
        return fileRef.then(() => {
            // get download directory
            fileRef.snapshot.ref.getDownloadURL().then(downloadURL => {
                dispatch(updateData(oldData, order, downloadURL));
                dispatch(fetchDone(''));
            });
        });
    };
}

// Async Action Creator
export function getData() {
    return async dispatch => {
        dispatch(fetch());

        const ref = firebase.firestore().collection('pages').doc('home');
        try {
            const result = await ref.get();
            let data = result.data().gallery;
            console.log(data);
            dispatch(fetchDataDone(data));
        } catch (error) {
            console.log(error);
            // doc.data() will be undefined in this case
            dispatch(showSnack('fail', 'No such document!'));
            dispatch(fetchFail('No such document!'));
        }
    };
}

export function postEdit(data) {
    return function (dispatch) {
        dispatch(fetch());

        const ref = firebase
            .firestore()
            .collection('pages')
            .doc('home');
        const inputData = {
            gallery: data,
        };

        return ref
            .set(inputData, { merge: true })
            .then(() => {
                dispatch(fetchDone('Gallery updated successfully.'));
                dispatch(
                    showSnack('success', 'Gallery updated successfully.'),
                );
            })
            .catch(error => {
                console.log(error);
                dispatch(fetchFail(error.message));
            });
    };
}
