import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Divider, AppBar, Tabs, Tab } from '@material-ui/core';

const CustomRouterLink = forwardRef((props, ref) => (
    <div ref={ref}>
        <RouterLink {...props} />
    </div>
));

const useStyles = makeStyles(theme => ({
    root: {},
    row: {
        height: '42px',
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(1)
    },
    spacer: {
        flexGrow: 1
    },
    searchInput: {
        marginRight: theme.spacing(1)
    },
    root2: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    tabs:{
        marginTop: 16,
        marginBottom: 16,
    },
    appbar: {
        boxShadow: 'none',
        backgroundColor: 'rgba(0,0,0,0)',
    },
    tab: {
        textTransform: 'none',
    },
}));

const UsersToolbar = props => {
    const { 
        reset, 
        tabActive,
    } = props;

    let tabValue = 0;
    if (tabActive === 'info') {
        tabValue = 1;
    }
    if (tabActive === 'masterplan') {
        tabValue = 2;
    }
    if (tabActive === 'unitplan') {
        tabValue = 3;
    }
    if (tabActive === 'floorplan') {
        tabValue = 4;
    }
    if (tabActive === 'gallery') {
        tabValue = 5;
    }
    if (tabActive === 'video') {
        tabValue = 6;
    }
    if (tabActive === 'map') {
        tabValue = 7;
    }
    if (tabActive === 'progress') {
        tabValue = 8;
    }

    const classes = useStyles();

    const tabs = [
        {
            title: 'Register',
            href: '/home/register',
        },
        {
            title: 'Project Info',
            href: '/home/info',
        },
        {
            title: 'Master Plan',
            href: '/home/masterplan',
        },
        {
            title: 'Unit Plan',
            href: '/home/unitplan',
        },
        {
            title: 'Floor Plan',
            href: '/home/floorplan',
        },
        {
            title: 'Gallery',
            href: '/home/gallery',
        },
        {
            title: 'Video',
            href: '/home/video',
        },
        {
            title: 'Map',
            href: '/home/map',
        },
        {
            title: 'Progress',
            href: '/home/progress',
        },
    ];

    return (
        <div className={classes.root}>
            <div className={classes.tabs}>
                <AppBar
                    position="static"
                    color="default"
                    className={classes.appbar}
                >
                    <Tabs
                        indicatorColor="primary"
                        value={tabValue}
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="simple tabs example"
                    >
                        {
                            tabs.map((item, key) =>
                                <Tab
                                    classes={{
                                        root: classes.tab,
                                    }}
                                    key={item.title}
                                    component={CustomRouterLink}
                                    id={`simple-tab-${key}`}
                                    aria-controls={`simple-tabpanel-${key}`}
                                    label={item.title}
                                    to={item.href}
                                    onClick={() => reset()}
                                    wrapped
                                />
                            )
                        }
                    </Tabs>
                </AppBar>
                <Divider />
            </div>
        </div>
    );
};

UsersToolbar.propTypes = {
    tabActive: PropTypes.string.isRequired,
};

export default UsersToolbar;
