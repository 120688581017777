import { connect } from 'react-redux';
import {
    reset,
    updateData,
    addList,
    removeList,
    postImage,
    getData,
    postEdit,
} from 'actions/neighbour';

import HomeNeighbour from 'views/Home/Neighbour';

const mapStateToProps = state => ({
    loading: state.neighbour.loading,
    data: state.neighbour.data,
    errors: state.neighbour.errors,
    isValid: state.neighbour.isValid,
});

const mapDispatchToProps = {
    reset,
    updateData,
    addList,
    removeList,
    postImage,
    getData,
    postEdit,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HomeNeighbour);

