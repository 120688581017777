import firebase from 'common/firebase';
import moment from 'moment';

// Action
export const FETCH_REGISTERS = 'FETCH_REGISTERS';
export const UPDATE_REGISTERS = 'UPDATE_REGISTERS';
export const FETCH_REGISTERS_DONE = 'FETCH_REGISTERS_DONE';
export const FETCH_REGISTERS_FAIL = 'FETCH_REGISTERS_FAIL';
export const FETCH_REGISTERS_LIST_DONE = 'FETCH_REGISTERS_LIST_DONE';

// Sync Action Creator
export function fetch() {
    return {
        type: FETCH_REGISTERS,
    };
}
export function fetchDone(message) {
    return {
        type: FETCH_REGISTERS_DONE,
        message,
    };
}
export function fetchFail(message) {
    return {
        type: FETCH_REGISTERS_FAIL,
        message,
    };
}
export function updateTime(field, value) {
    return {
        type: UPDATE_REGISTERS,
        field,
        value,
    };
}

// -- LIST --
export function fetchListDone(data) {
    return {
        type: FETCH_REGISTERS_LIST_DONE,
        list: data,
    };
}

// Async Action Creator
export function getList(start, end) {
    return async function(dispatch) {
        dispatch(fetch());

        const ref = firebase
            .firestore()
            .collection('registers')
            .where('timestamp', '>', start)
            .orderBy('timestamp', 'desc');
        try {
            const snapshot = await ref.get();
            const list = [];
            snapshot.forEach(doc => {
                const docData = doc.data();
                if (docData.timestamp < end) {
                    const data = {
                        ...docData,
                        uid: doc.id,
                        timestamp: moment(docData.timestamp).format('D/MM/YYYY, HH:mm'),
                    };
                    list.push(data);
                }
            });
            dispatch(fetchListDone(list));
        } catch (error) {
            console.log(error);
            dispatch(fetchFail(error.message));
        }
    };
}
