import {
    RESET_VIDEO,
    UPDATE_VIDEO,
    FETCH_VIDEO,
    FETCH_VIDEO_DATA_DONE,
    FETCH_VIDEO_DONE,
    FETCH_VIDEO_FAIL,
} from 'actions/video';

const initialState = {
    loading: false,
    data: {
        cover: '',
        url:'',
        coverTwo: '',
        urlTwo: '',
    },
    isValid: false,
    successMessage: '',
    failMessage: '',
};

const coverReducer = (state = initialState, action) => {
    switch (action.type) {
        case RESET_VIDEO:
            return {
                ...initialState,
                data: {
                    cover: '',
                    url: '',
                    coverTwo: '',
                    urlTwo: '',
                },
            };
        case UPDATE_VIDEO:
            return {
                ...state,
                data: {
                    ...state.data,
                    [action.fieldName]: action.newValue,
                },
                errors: action.errors,
                isValid: !action.errors,
            };            
        case FETCH_VIDEO:
            return {
                ...state,
                loading: true,
            };
        case FETCH_VIDEO_DATA_DONE:    
            return {
                ...state,
                loading: false,
                data: action.data,
            };
        case FETCH_VIDEO_DONE:
            return {
                ...state,
                loading: false,
                successMessage: action.message,
            };
        case FETCH_VIDEO_FAIL:
            return {
                ...state,
                loading: false,
                failMessage: action.message,
            };
        default:
            return state;
    }
};
export default coverReducer;
