import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import 'firebase/storage';
import 'firebase/functions';

// Initialize Firebase
const firebaseConfig = {
    apiKey: "AIzaSyCXg4GKzVah4wlMOGa-NIMe9YXnXVVAFl4",
    authDomain: "the-moment-of-living.firebaseapp.com",
    databaseURL: "https://the-moment-of-living.firebaseio.com",
    projectId: "the-moment-of-living",
    storageBucket: "the-moment-of-living.appspot.com",
    messagingSenderId: "561385942662",
    appId: "1:561385942662:web:33a9c885e2568b494e2b07",
    measurementId: "G-40T76D5LQ6"
};

// Initialize Firebase
if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

export default firebase;