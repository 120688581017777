import moment from 'moment';
import {
    FETCH_REGISTERS,
    UPDATE_REGISTERS,
    FETCH_REGISTERS_DONE,
    FETCH_REGISTERS_FAIL,
    FETCH_REGISTERS_LIST_DONE,
} from '../actions/registers';

const initialState = {
    loading: false,
    start: moment().subtract(1, 'months').valueOf(),
    end: moment().valueOf(),
    list: [],
    errors: {},
    successMessage: '',
    failMessage: '',
};

const tagsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_REGISTERS:
            return {
                ...state,
                loading: true,
            };
        case UPDATE_REGISTERS:
                return {
                    ...state,
                    [action.field]: action.value,
                };
        case FETCH_REGISTERS_DONE:
            return {
                ...state,
                loading: false,
                successMessage: action.message,
            };
        case FETCH_REGISTERS_FAIL:
            return {
                ...state,
                loading: false,
                failMessage: action.message,
            };
        case FETCH_REGISTERS_LIST_DONE:
            return {
                ...state,
                loading: false,
                list: action.list,
            };
        default:
            return state;
    }
};
export default tagsReducer;
