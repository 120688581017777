import {
    RESET_INTERIOR,
    UPDATE_INTERIOR,
    ADD_INTERIOR,
    REMOVE_INTERIOR,
    FETCH_INTERIOR,
    FETCH_INTERIOR_DATA_DONE,
    FETCH_INTERIOR_DONE,
    FETCH_INTERIOR_FAIL,
} from 'actions/interior';

const initialState = {
    loading: false,
    data: [],
    isValid: false,
    successMessage: '',
    failMessage: '',
};

const coverReducer = (state = initialState, action) => {
    switch (action.type) {
        case RESET_INTERIOR:
            return {
                ...initialState,
                data: [],
            };
        case ADD_INTERIOR:
            let newList = [...state.data];
            newList.push({
                desc:{
                    en: '',
                    th: '',
                },
                img: '',
            });

            return {
                ...state,
                data: newList,
                isValid: false,
            };
        case REMOVE_INTERIOR:
            return {
                ...state,
                data: action.data,
                isValid: action.isValid,
            };
        case UPDATE_INTERIOR:
            let newArr = [...state.data];
            let updatedObj = {
                ...state.data[action.order],
                desc: {
                    ...state.data[action.order].desc,
                    [action.lang]: action.newValue,
                }
            };
            if (action.lang === '') {
                updatedObj = {
                    ...state.data[action.order],
                    [action.fieldName]: action.newValue,
                };
            }
            return {
                ...state,
                data: [
                    ...newArr.slice(0, action.order),
                    updatedObj,
                    ...newArr.slice(action.order + 1),
                ],
                isValid: action.isValid,
            };            
        case FETCH_INTERIOR:
            return {
                ...state,
                loading: true,
            };
        case FETCH_INTERIOR_DATA_DONE:    
            return {
                ...state,
                loading: false,
                data: action.data,
            };
        case FETCH_INTERIOR_DONE:
            return {
                ...state,
                loading: false,
                successMessage: action.message,
            };
        case FETCH_INTERIOR_FAIL:
            return {
                ...state,
                loading: false,
                failMessage: action.message,
            };
        default:
            return state;
    }
};
export default coverReducer;
