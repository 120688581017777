import { connect } from 'react-redux';
import {
    reset,
    updateData,
    postImage,
    getData,
    postEdit,
} from 'actions/info';

import HomeEbrochure from 'views/Home/Info';

const mapStateToProps = state => ({
    loading: state.info.loading,
    data: state.info.data,
    errors: state.info.errors,
    isValid: state.info.isValid,
});

const mapDispatchToProps = {
    reset,
    updateData,
    postImage,
    getData,
    postEdit,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HomeEbrochure);

