import {
    RESET_NEIGHBOUR,
    UPDATE_NEIGHBOUR,
    ADD_NEIGHBOUR,
    REMOVE_NEIGHBOUR,
    FETCH_NEIGHBOUR,
    FETCH_NEIGHBOUR_DATA_DONE,
    FETCH_NEIGHBOUR_DONE,
    FETCH_NEIGHBOUR_FAIL,
} from 'actions/neighbour';

const initialState = {
    loading: false,
    data: [],
    isValid: false,
    successMessage: '',
    failMessage: '',
};

const coverReducer = (state = initialState, action) => {
    switch (action.type) {
        case RESET_NEIGHBOUR:
            return {
                ...initialState,
                data: [],
            };
        case ADD_NEIGHBOUR:
            let newList = [...state.data];
            newList.push('');

            return {
                ...state,
                data: newList,
                isValid: false,
            };
        case REMOVE_NEIGHBOUR:
            return {
                ...state,
                data: action.data,
                isValid: action.isValid,
            };
        case UPDATE_NEIGHBOUR:
            let newArr = [...state.data];
            return {
                ...state,
                data: [
                    ...newArr.slice(0, action.order),
                    action.newValue,
                    ...newArr.slice(action.order + 1),
                ],
                isValid: action.isValid,
            };
        case FETCH_NEIGHBOUR:
            return {
                ...state,
                loading: true,
            };
        case FETCH_NEIGHBOUR_DATA_DONE:
            return {
                ...state,
                loading: false,
                data: action.data,
            };
        case FETCH_NEIGHBOUR_DONE:
            return {
                ...state,
                loading: false,
                successMessage: action.message,
            };
        case FETCH_NEIGHBOUR_FAIL:
            return {
                ...state,
                loading: false,
                failMessage: action.message,
            };
        default:
            return state;
    }
};
export default coverReducer;
