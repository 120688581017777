import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { TableCell, TableRow } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    root: {
        left: -12,
    },
    nameContainer: {
        display: 'flex',
        alignItems: 'center',
    },
}));

const UsersTableList = props => {
    const classes = useStyles();

    const { data } = props;

    return (
        <TableRow className={classes.tableRow}>
            <TableCell>{data.timestamp}</TableCell>
            <TableCell>{data.name}</TableCell>
            <TableCell>{data.lname}</TableCell>
            <TableCell>{data.mobile}</TableCell>
            <TableCell>{data.email}</TableCell>
            <TableCell>{data.budget}</TableCell>
            <TableCell>{data.roomSize}</TableCell>
            <TableCell>{data.source}</TableCell>
            <TableCell>{data.medium}</TableCell>
            <TableCell>{data.campaignName}</TableCell>
            <TableCell>{data.term}</TableCell>
            <TableCell>{data.content}</TableCell>
        </TableRow>
    );
};

export default UsersTableList;
