import validate from 'validate.js';
import uuidv1 from 'uuid/v1';
import firebase from 'common/firebase';
import { showSnack } from './main';

const schema = {
    cover: {
        presence: { allowEmpty: false, message: 'is required' },
    },
};

// Action
export const RESET_COVER = 'RESET_COVER';
export const UPDATE_COVER = 'UPDATE_COVER';
export const FETCH_COVER = 'FETCH_COVER';
export const FETCH_COVER_DATA_DONE = 'FETCH_COVER_DATA_DONE';
export const FETCH_COVER_DONE = 'FETCH_COVER_DONE';
export const FETCH_COVER_FAIL = 'FETCH_COVER_FAIL';

// Sync Action Creator
export function reset() {
    return {
        type: RESET_COVER,
    };
};
export function fetch() {
    return {
        type: FETCH_COVER,
    };
};
export function fetchDataDone(cover) {
    return {
        type: FETCH_COVER_DATA_DONE,
        cover,
    };
};
export function fetchDone(message) {
    return {
        type: FETCH_COVER_DONE,
        message,
    };
};
export function fetchFail(message) {
    return {
        type: FETCH_COVER_FAIL,
        message,
    };
};
export function updateData(cover) {
    const validateData = { cover };
    const errors = validate(validateData, schema);

    return {
        type: UPDATE_COVER,
        newValue: cover,
        errors: errors,
    };
};

// Async Action Creator
export function postImage(file){
    if (!file) {
        return {
            type: FETCH_COVER_FAIL,
            message: 'No selected file.',
        };
    }

    // Add file to storage
    return function (dispatch) {
        dispatch(fetch());        
        const storageRef = firebase.storage().ref();
        const fileRef = storageRef.child(`pages/${uuidv1()}-${file.name}`).put(file);
        return fileRef.then(() => {
            // get download directory
            fileRef.snapshot.ref.getDownloadURL().then((downloadURL) => {
                dispatch(updateData(downloadURL));
                dispatch(fetchDone(''));
            });
        });
    };
}


// Async Action Creator
export function getData() {
    return async (dispatch) => {
        dispatch(fetch());

        const ref = firebase.firestore().collection("pages").doc("home");
        try {
            const result = await ref.get();
            const cover = result.data().cover.img;
            dispatch(fetchDataDone(cover));
        }
        catch (error) {
            console.log(error);
            // doc.data() will be undefined in this case
            dispatch(showSnack('fail', "No such document!"));
            dispatch(fetchFail("No such document!"));
        }
    };
}

export function postEdit(data) {
    return function (dispatch) {
        dispatch(fetch());
        
        const ref = firebase.firestore().collection("pages").doc("home");
        const inputData = {
            cover: {
                img: data,
            },
        };

        return ref.set(inputData, { merge: true }).then(() => {
            dispatch(fetchDone('Home cover updated successfully.'));
            dispatch(showSnack('success', 'Home cover updated successfully.'));
        }).catch((error) => {
            console.log(error);
            dispatch(fetchFail(error.message));
        });
    };
}