import { connect } from 'react-redux';
import {
    reset,
    updateData,
    addList,
    removeList,
    postImage,
    getData,
    postEdit,
} from 'actions/register';

import HomeGallery from 'views/Home/Register';

const mapStateToProps = state => ({
    loading: state.register.loading,
    data: state.register.data,
    errors: state.register.errors,
    isValid: state.register.isValid,
});

const mapDispatchToProps = {
    reset,
    updateData,
    addList,
    removeList,
    postImage,
    getData,
    postEdit,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HomeGallery);

