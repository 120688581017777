import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import { Toolbar } from './components';
import Details from './components/Progress';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3)
    },
    content: {
        marginTop: theme.spacing(2)
    },
}));

const RegisterData = (props) => {
    const classes = useStyles();
    const {
        loading,
        data,
        isValid,
        reset,
        updateData,
        postImage,
        getData,
        postEdit,
    } = props;

    React.useEffect(() => {
        getData();
    }, [getData]);

    return (
        <div className={classes.root}>
            <Toolbar tabActive="progress" reset={reset} />
            <div className={classes.content}>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Details
                            loading={loading}
                            data={data}
                            isValid={isValid}
                            updateData={updateData}
                            postImage={postImage}
                            postEdit={postEdit}
                        />
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

export default RegisterData;
