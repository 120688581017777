import {
    RESET_COVER,
    UPDATE_COVER,
    FETCH_COVER,
    FETCH_COVER_DATA_DONE,
    FETCH_COVER_DONE,
    FETCH_COVER_FAIL,
} from 'actions/cover';

const initialState = {
    loading: false,
    cover: '',
    errors: {},
    isValid: false,
    successMessage: '',
    failMessage: '',
};

const coverReducer = (state = initialState, action) => {
    switch (action.type) {
        case RESET_COVER:
            return {
                ...initialState,
                cover: '',
            };
        case UPDATE_COVER:            
            return {
                ...state,
                cover: action.newValue,
                errors: action.errors,
                isValid: !action.errors,
            };
        case FETCH_COVER:
            return {
                ...state,
                loading: true,
            };
        case FETCH_COVER_DATA_DONE:    
            return {
                ...state,
                loading: false,
                cover: action.cover,
            };
        case FETCH_COVER_DONE:
            return {
                ...state,
                loading: false,
                successMessage: action.message,
            };
        case FETCH_COVER_FAIL:
            return {
                ...state,
                loading: false,
                failMessage: action.message,
            };
        default:
            return state;
    }
};
export default coverReducer;
