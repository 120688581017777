import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
    Card,
    CardActions,
    Divider,
    Button,
    TextField,
    Box,
} from '@material-ui/core';
import ImageField from 'components/ImageField';

const useStyles = makeStyles(() => ({
    formControl: {
        width: '100%',
        maxWidth: 200,
        marginRight: 16,
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        justifyItems: 'center',
        marginBottom: 8,
    },
}));

const AccountDetails = props => {
    const classes = useStyles();
    const {
        loading,
        data,
        isValid,
        updateData,
        updateMap,
        postImage,
        postEdit,
    } = props;

    // Submit Form
    const handleSave = () => {
        postEdit(data);
    };

    return (
        <Card className={classes.root}>
            <ImageField
                title={'Map*'}
                width={900}
                height={900}
                loading={loading}
                url={data.image}
                uploadFunc={postImage}
                oldData={data}
                fieldName="map"
                removeFunc={() => updateMap(data, '')}
            />
            <Divider />
            <Box p={2}>
                <TextField
                    fullWidth
                    label="Project Location"
                    margin="normal"
                    name="projectLocation"
                    onChange={event =>
                        updateData(data, 'projectLocation', event.target.value)
                    }
                    value={data.projectLocation}
                    variant="outlined"
                    disabled={loading}
                />
                <TextField
                    fullWidth
                    label="Sales Gallery"
                    margin="normal"
                    name="salesGallery"
                    onChange={event =>
                        updateData(data, 'salesGallery', event.target.value)
                    }
                    value={data.salesGallery}
                    variant="outlined"
                    disabled={loading}
                />
            </Box>
            <Divider />
            <CardActions style={{ padding: 16 }}>
                <Button
                    color="primary"
                    variant="contained"
                    disabled={!isValid}
                    onClick={handleSave}>
                    Save
                </Button>
            </CardActions>
        </Card>
    );
};

export default AccountDetails;
