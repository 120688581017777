import { connect } from 'react-redux';
import {
    reset,
    updateData,
    postImage,
    getData,
    postEdit,
} from 'actions/video';

import HomeVideo from 'views/Home/Video';

const mapStateToProps = state => ({
    loading: state.video.loading,
    data: state.video.data,
    errors: state.video.errors,
    isValid: state.video.isValid,
});

const mapDispatchToProps = {
    reset,
    updateData,
    postImage,
    getData,
    postEdit,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HomeVideo);

