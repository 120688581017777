import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
    Card,
    CardContent,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@material-ui/core';
import _ from 'lodash';
import List from './List';
import LoadingList from './LoadingList';

const useStyles = makeStyles(() => ({
    content: {
        padding: 0,
    },
    inner: {
        minWidth: 1050,
    },
}));

const TagTable = props => {
    const classes = useStyles();

    const { list, loading } = props;

    return (
        <Card>
            <CardContent className={classes.content}>
                <PerfectScrollbar>
                    <div className={classes.inner}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Created Date</TableCell>
                                    <TableCell>Firstname</TableCell>
                                    <TableCell>Lastname</TableCell>
                                    <TableCell>Mobile</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell>Budget</TableCell>
                                    <TableCell>Room Size</TableCell>
                                    <TableCell>Source</TableCell>
                                    <TableCell>Medium</TableCell>
                                    <TableCell>Campaign Name</TableCell>
                                    <TableCell>Term</TableCell>
                                    <TableCell>Content</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {loading
                                    ? _.times(10, i => <LoadingList key={i} />)
                                    : list.map(item => (
                                          <List data={item} key={item.uid} />
                                      ))}
                            </TableBody>
                        </Table>
                    </div>
                </PerfectScrollbar>
            </CardContent>
        </Card>
    );
};

export default TagTable;
