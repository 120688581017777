import {
    SET_LANG,
    RESET_CONCEPT,
    UPDATE_CONCEPT,
    FETCH_CONCEPT,
    FETCH_CONCEPT_DATA_DONE,
    FETCH_CONCEPT_DONE,
    FETCH_CONCEPT_FAIL,
} from 'actions/concept';

const initialState = {
    loading: false,
    lang: 'th',
    data: {
        img:{
            one: '',
            two: '',
        },
        desc: {
            th: '',
            en: '',
        },
    },
    errors: {},
    isValid: false,
    successMessage: '',
    failMessage: '',
};

const conceptReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_LANG:
            return {
                ...state,
                lang: action.lang,
            };
        case RESET_CONCEPT:
            return {
                ...initialState,
                data: {
                    img: {
                        one: '',
                        two: '',
                    },
                    desc: {
                        th: '',
                        en: '',
                    },
                },
            };
        case UPDATE_CONCEPT:
            if (action.fieldName === 'one' || action.fieldName === 'two') {
                return {
                    ...state,
                    data: {
                        ...state.data,
                        img: {
                            ...state.data.img,
                            [action.fieldName]: action.newValue,
                        }
                    },
                    errors: action.errors,
                    isValid: !action.errors,
                };
            }
            
            return {
                ...state,
                data: {
                    ...state.data,
                    desc: {
                        ...state.data.desc,
                        [action.lang]: action.newValue,
                    },
                },
                errors: action.errors,
                isValid: !action.errors,
            };
        case FETCH_CONCEPT:
            return {
                ...state,
                loading: true,
            };
        case FETCH_CONCEPT_DATA_DONE:    
            return {
                ...state,
                loading: false,
                data: action.data,
            };
        case FETCH_CONCEPT_DONE:
            return {
                ...state,
                loading: false,
                successMessage: action.message,
            };
        case FETCH_CONCEPT_FAIL:
            return {
                ...state,
                loading: false,
                failMessage: action.message,
            };
        default:
            return state;
    }
};
export default conceptReducer;
