import {
    RESET_CONTACT,
    UPDATE_CONTACT,
    UPDATE_CONTACT_MAP,
    FETCH_CONTACT,
    FETCH_CONTACT_DATA_DONE,
    FETCH_CONTACT_DONE,
    FETCH_CONTACT_FAIL,
} from 'actions/contactus';

const initialState = {
    loading: false,
    data: {
        image: '',
        projectLocation: '',
        salesGallery: '',
    },
    isValid: false,
    successMessage: '',
    failMessage: '',
};

const coverReducer = (state = initialState, action) => {
    switch (action.type) {
        case RESET_CONTACT:
            return {
                ...initialState,
                data: {
                    image: '',
                    projectLocation: '',
                    salesGallery: '',
                },
            };
        case UPDATE_CONTACT_MAP:
            return {
                ...state,
                data: {
                    ...state.data,
                    image: action.newValue,
                },
                isValid: action.isValid,
            };
        case UPDATE_CONTACT:
            return {
                ...state,
                data: {
                    ...state.data,
                    [action.fieldName]: action.newValue,
                },
                isValid: action.isValid,
            };
        case FETCH_CONTACT:
            return {
                ...state,
                loading: true,
            };
        case FETCH_CONTACT_DATA_DONE:
            return {
                ...state,
                loading: false,
                data: action.data,
            };
        case FETCH_CONTACT_DONE:
            return {
                ...state,
                loading: false,
                successMessage: action.message,
            };
        case FETCH_CONTACT_FAIL:
            return {
                ...state,
                loading: false,
                failMessage: action.message,
            };
        default:
            return state;
    }
};
export default coverReducer;
