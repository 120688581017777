import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
    Card,
    CardActions,
    Divider,
    Button,
    Typography,
    // TextField,
    Box,
    IconButton,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import ImageWithTextField from 'components/ImageWithTextField';

const useStyles = makeStyles(() => ({
    formControl: {
        width: '100%',
        maxWidth: 200,
        marginRight: 16,
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        justifyItems: 'center',
    },
}));

const AccountDetails = props => {
    const classes = useStyles();
    const {
        loading,
        data,
        isValid,
        updateData,
        addList,
        removeList,
        postImage,
        postEdit,
    } = props;

    // Submit Form
    const handleSave = () => {
        postEdit(data);
    };

    return (
        <Card className={classes.root}>
            {data.map((item, index) => (
                <div key={index}>
                    <Box p={2}>
                        <div className={classes.header}>
                            <Typography variant="h5">
                                {`Image ${index + 1}`}
                            </Typography>
                            <IconButton onClick={() => removeList(data, index)}>
                                <DeleteIcon />
                            </IconButton>
                        </div>
                    </Box>
                    <ImageWithTextField
                        width={1080}
                        height={1080}
                        loading={loading}
                        url={item}
                        uploadFunc={file => postImage(file, data, index)}
                        oldData={data}
                        fieldName="img"
                        order={index}
                        removeFunc={() => updateData(data, index, '')}
                    />
                    <Divider />
                </div>
            ))}
            <Box p={2}>
                <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => addList()}
                    disabled={loading}>
                    Add Image
                </Button>
            </Box>
            <Divider />
            <CardActions style={{ padding: 16 }}>
                <Button
                    color="primary"
                    variant="contained"
                    disabled={!isValid}
                    onClick={handleSave}>
                    Save
                </Button>
            </CardActions>
        </Card>
    );
};

export default AccountDetails;
