import { connect } from 'react-redux';
import {
    reset,
    updateData,
    updateMap,
    postImage,
    getData,
    postEdit,
} from 'actions/contactus';

import HomeContact from 'views/Home/ContactUs';

const mapStateToProps = state => ({
    loading: state.contactus.loading,
    data: state.contactus.data,
    errors: state.contactus.errors,
    isValid: state.contactus.isValid,
});

const mapDispatchToProps = {
    reset,
    updateData,
    updateMap,
    postImage,
    getData,
    postEdit,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HomeContact);

