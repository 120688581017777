import {
    RESET_GALLERY,
    UPDATE_GALLERY,
    ADD_GALLERY,
    REMOVE_GALLERY,
    FETCH_GALLERY,
    FETCH_GALLERY_DATA_DONE,
    FETCH_GALLERY_DONE,
    FETCH_GALLERY_FAIL,
} from 'actions/gallery';

const initialState = {
    loading: false,
    data: [],
    isValid: false,
    successMessage: '',
    failMessage: '',
};

const coverReducer = (state = initialState, action) => {
    switch (action.type) {
        case RESET_GALLERY:
            return {
                ...initialState,
                data: [],
            };
        case ADD_GALLERY:
            let newList = [...state.data];
            newList.push('');

            return {
                ...state,
                data: newList,
                isValid: false,
            };
        case REMOVE_GALLERY:
            return {
                ...state,
                data: action.data,
                isValid: action.isValid,
            };
        case UPDATE_GALLERY:
            let newArr = [...state.data];
            return {
                ...state,
                data: [
                    ...newArr.slice(0, action.order),
                    action.newValue,
                    ...newArr.slice(action.order + 1),
                ],
                isValid: action.isValid,
            };
        case FETCH_GALLERY:
            return {
                ...state,
                loading: true,
            };
        case FETCH_GALLERY_DATA_DONE:
            return {
                ...state,
                loading: false,
                data: action.data,
            };
        case FETCH_GALLERY_DONE:
            return {
                ...state,
                loading: false,
                successMessage: action.message,
            };
        case FETCH_GALLERY_FAIL:
            return {
                ...state,
                loading: false,
                failMessage: action.message,
            };
        default:
            return state;
    }
};
export default coverReducer;
